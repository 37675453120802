<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {};
  },
  methods: {},
  mounted() { },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Minha Conta</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-md-6">
                <p class="mb-0"><strong>Nome</strong></p>
                <p class="mb-3 notranslate">{{ account.user.name }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-0"><strong>CPF</strong></p>
                <p class="mb-3 notranslate">{{ account.user.name }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-0"><strong>E-mail</strong></p>
                <p class="mb-3 notranslate">{{ account.user.email.address }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0 bg-soft-default">
          <div class="card-body">
            <strong>Alteração dos dados:</strong><br />
            Por questões de segurança, entre em contato pelo chat para solicitar
            alteração das informações.
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
